body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.loader {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100vh;
  background-color: #0d1019;
  overflow: hidden;
}
.loaderBody {
  width: 90%;
  background-color: rgba(255, 255, 255, 0.1);
  border-radius: 15px;
  animation: loaderAnimation 1s linear infinite alternate;
}
.loader > div:first-child {
  margin-top: 30px;
  height: 100px;
}
.loader > div:last-child {
  margin-top: 30px;
  margin-bottom: 30px;
  height: 100%;
}
@keyframes loaderAnimation {
  to {
    background-color: rgba(255, 255, 255, 0.5);
  }
}
